import React from "react";
import ItemCard from "../Cards/ItemCard";
import FirebasePageBuilder from "../PageBuilder/FirebasePageBuilder";

const id = "bills";

const ItemsView = () => {
    return (
        <FirebasePageBuilder
            id={id}
            url={id}
            itemBuilder={ItemCard}
            onResult={(data) =>
                data.map((item) => {
                    if (!item.image) item.image = "https://d1nhio0ox7pgb.cloudfront.net/_img/o_collection_png/green_dark_grey/512x512/plain/object_cube.png";
                    return item;
                })
            }
        />
    );
};

export default ItemsView;
