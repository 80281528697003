import { Div } from "../NodeBuilder/NodeBuilder.js";
import { GetItems } from "../Utils/MockGenerator.js";
import Utils from "../Utils/Utils.js";
import { getApi } from "./Fetcher.js";
import UrlsGenerater from "./UrlGenertor.js";

export default class CreateProvider {
    constructor({
        api,
        apiId,
        url,
        limit = 25,
        onSearch,
        onFetch,
        onResult,
        onError,
        autoFetch,
        sessionKey,
        storageKey,
        onQueryChanged,
        providerId,
        useRecicler = true,
        scrollerId = "wrapper",
        firebase,
    }) {
        this.canFetch = true;
        this.useRecicler = useRecicler;
        this.offset = 0;
        this.api = api ?? getApi(apiId ?? providerId);
        this.id = providerId;
        this.url = url;
        this.query = "";
        this.limit = limit;
        this.scrollerId = scrollerId;
        this.scrollPostion = localStorage.getItem(`${this.id}-scrollPostion`) || 0;

        this.onSearch = onSearch;
        this.onFetch = onFetch;
        this.onResult = onResult;
        this.onError = onError;
        this.autoFetch = autoFetch;
        this.header = {};
        this.firebase = firebase;

        this.setProviderState = () => {};
        this.providerState = "none";

        this.setItems = () => {};
        this.items = [];

        this.setupStorage(this, sessionKey, storageKey);

        this.setupQuery(onQueryChanged);
        this.billItems = {};

        this.search = setupSearchEvent(this);
        this.fetch = setupFetchEvent(this);

        if (!this.api)
            setTimeout(() => {
                this.search();
            }, 500);
        addScrollEvent(this);

        window.addEventListener("contextmenu", (e) => {
            e.preventDefault();
            this.removeSession && this.removeSession();
            window.location.reload();
            // this.search();
        });
    }

    setupQuery(onQueryChanged) {
        this.queryParams = {};

        this.setHeader = (header) => {
            this.setHeaderState &&
                this.setHeaderState((_prev) => {
                    let _newHeader = { ..._prev, ...header };
                    if (this.useCash) {
                        this.setToStorage("-header", _newHeader);
                    }
                    return _newHeader;
                });
        };

        if (onQueryChanged) {
            this.setQueryParmas = async (values) => {
                this.queryParams = values;
                let header = this.getStored("-header");
                if (header) {
                    this.header = header;
                } else {
                    setTimeout(() => {
                        this.setHeader(onQueryChanged(this, values, true));
                    }, 1);
                }
                this.search(true);
            };
            this.updateQueryParams = (child) => {
                if (Utils.hasValue(child.value)) this.queryParams[child.key] = { value: child.value, title: child.title || "_" };
                else delete this.queryParams[child.key];
                onQueryChanged(this, { [child.key]: child });
                this.search();
            };
        } else {
            if (this.useCash)
                setTimeout(() => {
                    let header = this.getStored("-header");
                    this.setHeader(header);
                }, 1);

            this.setQueryParmas = (values) => {
                this.queryParams = values;
                this.search(true);
            };
            this.updateQueryParams = (child) => {
                if (child.value) this.queryParams[child.key] = { value: child.value, title: child.title || "_" };
                else delete this.queryParams[child.key];
                this.search();
            };
        }
    }

    setupStorage(apiProvider, sessionKey, storageKey) {
        apiProvider.useCash = true;

        const getCleanString = (text) => {
            if (text) text = text.replace(/[?&=/!]/g, "-");
            return apiProvider.storeKey + text;
        };
        if (storageKey) {
            apiProvider.storeKey = storageKey;
            apiProvider.getStored = (store_key) => {
                let _storeKey = getCleanString(store_key);
                return JSON.parse(localStorage.getItem(_storeKey));
            };
            apiProvider.setToStorage = (store_key, data) => {
                let _storeKey = getCleanString(store_key);
                if (Object.values(data).length > 0) localStorage.setItem(_storeKey, JSON.stringify(data));
                else localStorage.removeItem(_storeKey);
            };
        } else if (sessionKey) {
            apiProvider.removeSession = () => {
                removeSession(sessionKey);
            };
            apiProvider.storeKey = sessionKey;
            apiProvider.getStored = (store_key) => {
                let _storeKey = getCleanString(store_key);
                return JSON.parse(sessionStorage.getItem(_storeKey));
            };
            apiProvider.setToStorage = (store_key, data) => {
                let _storeKey = getCleanString(store_key);
                if (Object.values(data).length > 0) sessionStorage.setItem(_storeKey, JSON.stringify(data));
                else sessionStorage.removeItem(_storeKey);
            };
        } else apiProvider.useCash = false;
    }
}

const setupFetchEvent = (apiProvider) => async () => {
    apiProvider.canFetch = false;
    let query =
        apiProvider.query + (apiProvider.firebase ? `&startAfter=${apiProvider.items[apiProvider.items.length - 1].id}` : `&offset=${apiProvider.offset}`);
    apiProvider.setProviderState("itemsLoading");

    apiProvider.onFetch && apiProvider.onFetch(apiProvider);
    try {
        const result = apiProvider.api ? await apiProvider.api.get(query) : GetItems(apiProvider);
        onResult(result, apiProvider);
    } catch (error) {
        onError(error, apiProvider);
    }
};

function setupSearchEvent(apiProvider) {
    return async (init, reloader) => {
        apiProvider.canFetch = false;
        apiProvider.offset = 0;
        if (!apiProvider.queryParams.limit && apiProvider.limit) apiProvider.queryParams.limit = { value: apiProvider.limit, title: "_" };

        apiProvider.query = UrlsGenerater(apiProvider.queryParams, apiProvider.url);
        apiProvider.onSearch && apiProvider.onSearch(apiProvider);
        if (apiProvider.useCash) {
            let cashItems = apiProvider.getStored(apiProvider.query);
            if (cashItems) {
                if (init) {
                    apiProvider.items = cashItems;
                    apiProvider.offset = cashItems.length;
                } else apiProvider.setItems(cashItems);
                setTimeout(() => {
                    apiProvider.canFetch = true;
                }, 10);
                return;
            }
        }
        if (!reloader) {
            apiProvider.providerState = "searching";
            apiProvider.setProviderState("searching");
        }
        try {
            const result = apiProvider.api ? await apiProvider.api.get(apiProvider.query) : GetItems(apiProvider);
            onResult(result, apiProvider);
            if (reloader) {
                reloader?.setAttribute("is", "disappearing");
                setTimeout(() => {
                    reloader?.remove();
                }, 200);
            }
        } catch (error) {
            onError(error, apiProvider);
        }
    };
}

const onResult = async (data, apiProvider) => {
    if (apiProvider.onResult) {
        let modfied = await apiProvider.onResult(data, apiProvider);
        if (modfied) data = modfied;
    }
    let items = [];
    let _data = {};
    if (getType(data) === "Object") {
        Object.entries(data).forEach(([key, value]) => {
            if (Array.isArray(value)) items = value;
            else _data[key] = value;
        });
    } else items = data || [];

    if (apiProvider.useCash)
        if (apiProvider.offset === 0) {
            let allCashQueries = apiProvider.getStored("") || [];
            if (!allCashQueries.includes(apiProvider.query)) {
                allCashQueries.push(apiProvider.query);
                apiProvider.setToStorage("", allCashQueries);
            }
            apiProvider.setToStorage(apiProvider.query, items);
        } else {
            let oldItems = apiProvider.getStored(apiProvider.query) || [];
            apiProvider.setToStorage(apiProvider.query, [...oldItems, ...items]);
        }

    if (apiProvider.offset === 0) apiProvider.setItems(items);
    else
        apiProvider.setItems((_prev) => {
            return [..._prev, ...items];
        });

    apiProvider.offset += items.length;
    setTimeout(() => {
        apiProvider.canFetch = apiProvider.limit && items.length >= apiProvider.limit;
    }, 100);

    apiProvider.setProviderState(apiProvider.offset === 0 && items.length > 0 ? "noData" : "none");

    if (apiProvider.autoFetch) {
        apiProvider.setProviderState("processing");
        const scroller = document.getElementById(apiProvider.scrollerId);
        setTimeout(() => {
            scroller.scrollTo({ top: scroller.scrollHeight, left: 0 });
        }, 1);
    }
};

const initialiser = (apiProvider) => {
    setTimeout(() => {
        apiProvider.search(true);
    }, 10);
};

const getType = (obj) => Object.prototype.toString.call(obj).slice(8, -1);

function onError(error, apiProvider) {
    apiProvider.onError && apiProvider.onError(error);
    if (error.stack) error = { message: error.message, stack: error.stack };
    apiProvider.setProviderState({ state: "error", error });
}
//! Scroll Event
const addScrollEvent = (apiProvider) => {
    let reloader = createReloader();
    reloader.remove = () => {
        reloader?.setAttribute("is", "disappearing");
        setTimeout(() => {
            reloader.style = "opacity:0;width:60px;margin-inline:auto;height:80px;margin-top:-80px;padding-top:20px;z-index:-1;position:relative;display:none";
        }, 200);
    };
    reloader.remove();

    let diff = 0;
    setTimeout(() => {
        let scroller = document.getElementById(apiProvider.scrollerId);
        if (!scroller) return;
        setTimeout(() => {
            scroller.scrollBy({ top: apiProvider.scrollPostion, left: 0 });
        }, 100);
        const firstPath = reloader.firstChild.lastChild;

        const onSwipeDown = ({ touches }) => {
            diff = touches[0].clientY - apiProvider.startY;
            if (diff > 0) {
                if (diff > 200) diff = 200;
                let diffPersent = diff / 100;
                let offset = (1 - diffPersent) * reloader.offsetHeight;
                let dashOffset = 650 + diffPersent * 650;
                reloader.style.marginTop = -offset + "px";
                reloader.style.opacity = diffPersent;
                firstPath.style.strokeDashoffset = dashOffset;
            }
        };

        const touchEnd = () => {
            scroller.removeEventListener("touchend", touchEnd, { passive: true });
            scroller.removeEventListener("touchmove", onSwipeDown, { passive: true });

            if (diff < 100) {
                reloader.setAttribute("is", "disappearing");
                reloader.style.marginTop = "-80px";
                setTimeout(() => {
                    reloader?.remove();
                    apiProvider.pulling = false;
                }, 200);
                return;
            }
            firstPath.style.strokeDashoffset = 1300;
            reloader.setAttribute("is", "reloading");
            setTimeout(async () => {
                apiProvider.removeSession();
                await apiProvider.search(false, reloader);
                apiProvider.pulling = false;
            }, 200);
        };

        setTimeout(() => {
            if (scroller.childElementCount > 1) {
                scroller.insertBefore(reloader, scroller.childNodes[1]);
            } else scroller.insertBefore(reloader, scroller.firstChild);
        }, 100);

        scroller.addEventListener("touchstart", (e) => {
            if (scroller.scrollTop > 5 || !apiProvider.removeSession || apiProvider.pulling) return;
            apiProvider.pulling = true;
            reloader.style.opacity = "0";
            reloader.style.marginTop = "-80px";
            reloader.setAttribute("is", "pulling");
            diff = 0;

            scroller.addEventListener("touchmove", onSwipeDown, { passive: true });
            scroller.addEventListener("touchend", touchEnd, { passive: true });
            apiProvider.startY = e.touches[0].clientY;
        });
        if (apiProvider.limit !== 0)
            scroller.addEventListener(
                "scroll",
                ({ target }) => {
                    if (apiProvider.canFetch && target.scrollHeight - target.scrollTop < target.clientHeight + 400) {
                        apiProvider.canFetch = false;
                        apiProvider.fetch();
                    }
                },
                { passive: true }
            );

        // window.addEventListener("hashchange", () => {
        //     localStorage.setItem(`${apiProvider.id}-scrollPostion`, scroller.scrollTop);
        // });
    }, 100);
};

const removeSession = (sessionKey) => {
    // let type = window.performance.getEntriesByType("navigation")[0];
    // return;
    // if (type === "reload") {
    let keysToRemove = [];
    for (let i = 0; i < sessionStorage.length; i++) {
        let key = sessionStorage.key(i);
        if (key.includes(sessionKey)) {
            keysToRemove.push(key);
        }
    }
    keysToRemove.forEach((key) => sessionStorage.removeItem(key));
    // }
};

// LOCAL FETCHING

const createReloader1 = (threshold = 50) => {
    // style: `right:50%; width:50px;right:calc(50%-25px);top:0; margin-right:-50px;margin-top:50px;z-index:10000;position:fixed;`,
    return Div({
        id: "owl-reloader",
        innerHTML: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="-50 -50 500 500"><path class="reload-squiggle-back" fill="none" stroke="#DA374633" stroke-width="47" d="M111.6,344.3h217.2c33.8-2.5,61.2-29.9,61.2-63.7V88.8c0-33.9-27.5-61.4-61.4-61.4H87.3c-33.8,0-61.1,27.4-61.1,61.1l0.6,238.7c0.1,34.6,28.3,62.5,63.1,62.5h67.8"></path><path fill="none" stroke="#DA3746" stroke-width="46" class="reload-squiggle" d="M111.6,344.3h217.2c33.8-2.5,61.2-29.9,61.2-63.7V88.8c0-33.9-27.5-61.4-61.4-61.4H87.3c-33.8,0-61.1,27.4-61.1,61.1l0.6,238.7c0.1,34.6,28.3,62.5,63.1,62.5h67.8"></path></svg>`,
    });
};

const createReloader = (threshold = 50) =>
    // style: `position: absolute; margin-top:${threshold}px;top: 0; z-index: 100; width: 40px; left: calc(50% - 20px); fill: #DD3643;`,
    Div(
        {
            id: "owl-reloader",
            style: `width: 30px;margin-inline:auto;position:relative;`,
        },
        [
            Div({
                style: "fill: #DD3643;background-color:#ffffff;border-radius :50%;padding:10px;box-shadow : 0 0 8px #0003;",
                innerHTML: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489.645 489.645" ><path   d="M4100.656,132.911c-58.7-122.1-212.2-166.5-331.8-104.1c-9.4,5.2-13.5,16.6-8.3,27c5.2,9.4,16.6,13.5,27,8.3c99.9-52,227.4-14.9,276.7,86.3c65.4,134.3-19,236.7-87.4,274.6c-93.1,51.7-211.2,17.4-267.6-70.7l69.3,14.5c10.4,2.1,21.8-4.2,23.9-15.6c2.1-10.4-4.2-21.8-15.6-23.9l-122.8-25c-20.6-2-25,16.6-23.9,22.9l15.6,123.8c1,10.4,9.4,17.7,19.8,17.7c12.8,0,20.8-12.5,19.8-23.9l-6-50.5c57.4,70.8,170.3,131.2,307.4,68.2C414.856,432.511,548.256,314.811,4100.656,132.911z"></path></svg>`,
            }),
        ]
    );

// function localFetching(apiProvider) {
//     return async (init, reloader) => {
//         apiProvider.canFetch = false;
//         apiProvider.offset = 0;
//         if (!apiProvider.queryParams.limit && apiProvider.limit) apiProvider.queryParams.limit = { value: apiProvider.limit, title: "_" };

//         apiProvider.query = UrlsGenerater(apiProvider.queryParams, apiProvider.url);
//         apiProvider.onSearch && apiProvider.onSearch(apiProvider);

//         if (apiProvider.useCash) {
//             let cashItems = apiProvider.getStored(apiProvider.query);
//             if (cashItems) {
//                 if (init) apiProvider.items = cashItems;
//                 else apiProvider.setItems(cashItems);
//                 setTimeout(() => {
//                     apiProvider.canFetch = true;
//                 }, 10);
//                 return;
//             }
//         }
//         if (!reloader) {
//             apiProvider.providerState = "searching";
//             apiProvider.setProviderState("searching");
//         }
//         try {
//             // const result = await apiProvider.api.get(apiProvider.query);
//             const result = GetItems(apiProvider);
//             onResult(result, apiProvider);
//             if (reloader) {
//                 reloader?.setAttribute("is", "disappearing");
//                 setTimeout(() => {
//                     reloader?.remove();
//                 }, 200);
//             }
//         } catch (error) {
//             onError(error, apiProvider);
//         }
//     };
// }

// const LocalSetOnResult = async (apiProvider, items) => {
//     if (apiProvider.onResult) {
//         let modfied = await apiProvider.onResult(items);
//         if (modfied) items = modfied;
//     }
//     apiProvider.offset += items.length;
//     apiProvider.canFetch = items.length == apiProvider.limit;
//     apiProvider.setItems(items);
// };
