import React from "react";
import { useLocation } from "react-router-dom";

let pastLocatino = window.location.pathname;
const ListnToLocation = ({ onChange }) => {
    const location = useLocation();
    React.useEffect(() => {
        if (location.pathname === pastLocatino) return;
        pastLocatino = location.pathname;
        onChange(location);
    }, [location]);

    return <></>;
};

export default React.memo(ListnToLocation);
