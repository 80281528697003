import React from "react";
import { JsonBuilder } from "../NodeBuilder/JsonParser";

const GridHeader = ({
    provider,
    builder: Builder = ({ provider }) => <JsonBuilder item={provider.header} />,
    id = "grid-header",
    className = "items-header-container",
}) => {
    [provider.header, provider.setHeaderState] = React.useState(provider.header);

    return provider.header ? (
        <div id={id} className={className}>
            <Builder provider={provider} />
        </div>
    ) : (
        <></>
    );
};

export default GridHeader;
