import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import TestView from "../Pages/TestView";
import SpliterTest from "../Pages/SpliterTest";
import Dashboard from "../Pages/Dashboard";
import ItemsView from "../Pages/ItemsView";
import CategoriesView from "../Pages/CategoriesView";
import Svgs from "../Utils/Icons";
import ListnToLocation from "./ListnToLocation";

export const _AuthedRoutes = [
    { path: "/", element: <Dashboard />, title: "Dashboard" },
    // { path: "/customers", element: <Cutstomers />, title: "Cutstomers" },
    { path: "/items", element: <ItemsView />, title: "المواد", icon: "Items" },
    { path: "/categories", element: <CategoriesView />, title: "التصنيفات" },
    { path: "/test", element: <TestView />, title: "TestView" },
    // { path: "/spliter", element: <SpliterTest />, title: "SpliterTest" },
];

const AuthedRoutes = () => {
    console.log("Render");

    return (
        <>
            <Routes>
                {_AuthedRoutes.map((route) => (
                    <Route key={route.path} path={route.path} element={route.element} />
                ))}
            </Routes>
            <div className="row-center fixed bottom-0 gap-lg justify-center item-center m-auto left-0 right-0 pointer-events-none bg-blur">
                {_AuthedRoutes.slice(1).map(({ path, title, icon }) => (
                    <Link
                        to={path}
                        key={path}
                        onClick={({ target }) => {
                            // target.setAttribute("itemType", "active-tab");
                            // document.querySelector(["itemType=active-tab"])?.setAttribute("itemType", "un-active-tab");
                        }}
                        itemType={window.location.pathname === path ? "active-tab" : "un-active-tab"}
                        className="pointer-events-auto col-center">
                        {Svgs[icon || "Items"]}
                        <p className="font-bold text-xs">{title}</p>
                        {/* <p className="text-prim font-bold bg-crow p-xl rounded-xl text-center">{title}</p> */}
                    </Link>
                ))}
                <ListnToLocation
                    onChange={(location) => {
                        const link = document.querySelector(`[href="${location.pathname}"]`);
                        if (!link) return;
                        const oldLink = document.querySelector("[itemType=active-tab]");
                        if (oldLink) oldLink.setAttribute("itemType", "un-active-tab");
                        link.setAttribute("itemType", "active-tab");
                    }}
                />
            </div>
        </>
    );
};

export default AuthedRoutes;
