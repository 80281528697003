import React from "react";
const InputText = ({ value, placeholder, setValue, type = "text", className }) => (
    <input
        type={type}
        className={"owl-input " + className}
        value={value}
        placeholder={placeholder}
        onChange={({ target }) => {
            setValue(
                target.value.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (d) => {
                    return d.charCodeAt(0) - 1632;
                })
            );
        }}
    />
);
export default InputText;
