import React from "react";
import FirebasePageBuilder from "../PageBuilder/FirebasePageBuilder";
const CategoriesView = () => {
    return <FirebasePageBuilder useStorage onResult={onResult} />;
};

const onResult = (data) => {
    console.log(data);
};

export default CategoriesView;
