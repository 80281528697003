import GridHeader from "../Containers/GridHeader";
import Logger from "../NodeBuilder/Logger";
import React from "react";
import Grid from "../Containers/Grid";
import FormContainer from "../Form/FormContainer";
import { JsonBuilder } from "../NodeBuilder/JsonParser";
import PageStateProvider from "../PageStateKit/PageStateProvider";
import CreateProvider from "../scripts/CreateProvider";
import { getApi } from "../scripts/Fetcher";

const id = "reps";

const TestView = () => {
    const provider = React.useMemo(
        () =>
            new CreateProvider({
                url: "items",

                apiId: id,
                providerId: id,
                api: getApi(id), // default  api ?? getApi(apiId ?? providerId)

                limit: 25, //default=25

                onError: async (_error) => {},
                onFetch: async (_provider) => {},
                onSearch: async (_provider) => {},
                onQueryChanged: async (_provider) => {
                    const { query, items } = _provider;
                    _provider.setHeader({ query, itemsLength: items.length });
                },

                onResult: (data, _provider) => {
                    data?.map((item) => {
                        item.name = "changed";
                        return item;
                    });
                    return data;
                },

                scrollerId: "wrapper", // default="wrapper"  // main conatinerId to add scroll event for pagination
                sessionKey: `stored-${id}`, // store items to session storage,
                // storageKey: `stored-${id}id`, // store items to local storage,
            }),
        []
    );

    return (
        <div id="wrapper" className="wrapper scroller">
            <FormContainer children={queryParams} provider={provider} />
            <GridHeader provider={provider} />
            <Grid provider={provider} ItemBuilder={Card} />
            <PageStateProvider provider={provider} />
        </div>
    );
};

export default TestView;

const Card = ({ item, provider }) => {
    return (
        <JsonBuilder
            item={item}
            onClick={() => {
                item.count = item.count ? item.count + 1 : 1;
                provider.setItem(item);
                // Logger(item);
            }}
        />
    );
};

const queryParams = [
    {
        className: "form-section px-4",
        children: [
            {
                key: "name",
                type: "text",
                title: "بحث عن",
                value: "",
                storageKey: "items-query",
                containerClass: "query-input",
            },
            {
                key: "sort",
                type: "popupSelector",
                value: "",
                id: "sort-selector",
                className: "selector",
                storageKey: "selected-sort",
                options: [
                    { title: "بدون", id: "-1", dispalyTitle: "بدون ترتيب" },
                    { title: "الاسم", id: "name" },
                    { title: "التاريخ", id: "date" },
                ],
            },
            {
                key: "deleted",
                type: "boolean",
                storageKey: "deleted",
                value: true,
                title: "المحذوفة",
            },

            // {
            //     key: "from",
            //     type: "date",
            //     storageKey: "date-from",
            //     title: "من",
            // },

            // {
            //     key: "name-barcode",
            //     type: "scanner",
            //     value: "",
            // },
        ],
    },
];

const sample = {
    id: "943-34234kf-f32f-23f32f-c8",
    name: "Jhon Doe",
    description: "somthing going on here ok then this is a description so that is not a good idea",
    amBoolean: true,
    objectColction: [{ id: "KKKK" }, { name: "Obdestest" }, { description: "obdes" }],
    colction: ["one ", "test", "four", "four", "four", "owls"],
    object: {
        id: "K-sdf-KK-sdfK",
        name: "Jhon Doe",
        description: "obdes",
        description: "this is a description",
        idK: "K-sdf-KK-sdfK",
        nameK: "Obdes test by Jhon Doe",
    },
};
