import React from "react";
import Grid from "../Containers/Grid";
import GridHeader from "../Containers/GridHeader";
import FormContainer from "../Form/FormContainer";
import PageStateProvider from "../PageStateKit/PageStateProvider";
import CreateProvider from "../scripts/CreateProvider";
import PopupBuilder from "../Containers/PopupBuilder";
import FirebaseApi from "../Firebase/FirebaseApi";
import Logger from "../NodeBuilder/Logger";
const queryParams = [
    {
        children: [
            {
                type: "text",
                key: "name",
                title: "Name",
                value: "",
                placeholder: "Name",
            },
            {
                type: "none",
                key: "orderBy",
                title: "Name",
                value: "id",
            },
        ],
    },
];
const FirebasePageBuilder = ({
    // url,
    // id,
    useStorage = true,
    apiId,
    onResult,
    queryChanged,
    formBuilderClass,
    gridClass,
    // queryParams,
    itemBuilder: ItemBuilder,
    headerBuilder: HeaderBuilder,
    onItemClicked,
    hasPopup,
    api,
}) => {
    const id = window.location.pathname.replace("/", "");

    const provider = React.useMemo(() => {
        console.debug(id + " RD");
        let _provider = new CreateProvider({
            url: id,
            scrollerId: `${id}-wrapper`,
            sessionKey: `stored-${id}`,
            onResult: onResult,
            onQueryChanged: queryChanged,
            providerId: id,
            api: FirebaseApi,
            apiId: apiId || id,
            limit: 10,
            storageKey: useStorage ? `stored-${id}` : null,
            firebase: true,
        });

        _provider.onItemClicked = onItemClicked;
        // _provider.queryParams["startAt"] = { value: "", title: "_" };
        _provider.queryParams["orderBy"] = { value: "id", title: "_" };

        if (!queryParams) _provider.search();
        return _provider;
    }, []);

    return (
        <div id={`${id}-wrapper`} className="wrapper scroller">
            {queryParams && <FormContainer provider={provider} children={queryParams} className={formBuilderClass} />}
            {HeaderBuilder && <GridHeader provider={provider} builder={HeaderBuilder} />}
            <Grid provider={provider} ItemBuilder={ItemBuilder} className={gridClass} />
            <PageStateProvider provider={provider} />
            {hasPopup && <PopupBuilder provider={provider} id={id} />}
        </div>
    );
};

export default FirebasePageBuilder;
