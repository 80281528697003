import React from "react";
import Svgs from "../Utils/Icons";
// import CounterForm from "../../Components/CounterForm";

const ItemCard = ({ item, update }) => {
    const [, setQuantity] = React.useState(item.quantity);

    function changeQuantity(value = 1) {
        !item.quantity && (item.quantity = 0);
        item.quantity += value;
        setQuantity(item.quantity);
        update(item);
    }
    function handleFavorite() {
        item.favorite = !item.favorite;
        update(item);
    }
    return (
        <div className={`item-card relative bg-prim ${item.quantity > 0 && "chosen"}`}>
            <div className="flex-grow row">
                <img src={item.image} alt={item.name} />
                <p className="text-crow px-md text-sm">
                    <span>{item.name}</span>
                    <br />
                    <br />
                    <span> {item.currencyPrice} </span>
                </p>

                <div onClick={handleFavorite} className={`transition-2 ${item.favorite ? "favorite" : "un-favorite"}`} style={{ marginRight: "auto" }}>
                    {Svgs.Heart}
                </div>
            </div>
            {/* <CounterForm item={item} onClick={changeQuantity} price={item.price} onTextClick={() => update(item, "popup")} /> */}
        </div>
    );
};

export default ItemCard;
