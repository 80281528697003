import "./index.css";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Capacitor } from "@capacitor/core";
// import WebInit from "./Utils/WebInit";
import CapacitorInit from "./Utils/CapacitorInit";
import App from "./App";
import FirstSetup from "./FirstSetup";
import Logger from "./NodeBuilder/Logger";
import SparetiveLine from "./scripts/SparetiveLine";
import FirebaseApi from "./Firebase/FirebaseApi";
localStorage.setItem("token", "anfn6AxJdP18CbJdR96Q7dcgwpkNGrK9m3lHAIUfeGtLC8H7");

let token = localStorage.getItem("token");
FirstSetup();
const testInit = async () => {
    const data = await FirebaseApi.get("items", "Vigtibless");
    // const sdata = await FirebaseApi.get("items", "Vigtibless");
    // Logger(data);
};
// testInit();

// document.documentElement.className = "light";

if (Capacitor.isNativePlatform()) CapacitorInit();
// else WebInit();

createRoot(document.getElementById("root")).render(
    <Router>
        <App token={token} />
    </Router>
);
