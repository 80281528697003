import React from "react";

export const Icons = (Key, className = "icon") => {
    return <img src={Icons[Key]} className={className} />;
};

const Svgs = {
    Logo: (
        <svg xmlns="http://www.w3.org/2000/svg" style={{ padding: 20, maxHeight: 200, marginBottom: 20 }} height="40vh" viewBox="0 0 544.22 544.22">
            <path
                className="fill-logo"
                d="M630.79,247.24H360A136.67,136.67,0,0,0,223.18,383.8V654.69A136.66,136.66,0,0,0,359.74,791.46H630.62A136.66,136.66,0,0,0,767.4,654.91V384A136.69,136.69,0,0,0,630.79,247.24Zm-.2,477a62.62,62.62,0,0,1-51.39-51.4h37.27a26.59,26.59,0,0,0,49,0h37.28A62.62,62.62,0,0,1,630.59,724.22Z"
                transform="translate(-223.18 -247.24)"
            />
            <g id="Layer_4" data-name="Layer 4">
                <path
                    className="fill-prim"
                    d="M639.69,522.41A62.6,62.6,0,0,0,583,611.62H543.82V523h-36V662.67h0a27.23,27.23,0,0,1-27.2,27.19v36a63.3,63.3,0,0,0,63.22-63h0V647.64h95.87a62.62,62.62,0,0,0,0-125.23Zm0,89.21h0A26.59,26.59,0,1,1,666.28,585,26.62,26.62,0,0,1,639.68,611.62Z"
                    transform="translate(-223.18 -247.24)"
                />
                <path
                    className="fill-prim"
                    d="M349.51,689.81a26.62,26.62,0,0,1-26.59-26.59h-36A62.69,62.69,0,0,0,349,725.83v0h63.15v-36H349.51Z"
                    transform="translate(-223.18 -247.24)"
                />
                <circle className="fill-prim" cx="239.38" cy="421.39" r="18.01" />
                <path
                    className="fill-prim"
                    d="M444,523v61.42h0a27.18,27.18,0,0,1-27.19,27.19H349.51a26.62,26.62,0,0,1-26.59-26.44h0a26.59,26.59,0,0,1,53.18,0h36a62.62,62.62,0,0,0-125.23-.15h0A62.69,62.69,0,0,0,349,647.66h67.78A63.21,63.21,0,0,0,480,584.46h0V523Z"
                    transform="translate(-223.18 -247.24)"
                />
            </g>
            <path
                fill="#2d2d35"
                opacity={0.5}
                d="M702.71,672.82a62.62,62.62,0,0,1-123.51,0h37.27a26.59,26.59,0,0,0,49,0Z"
                transform="translate(-223.18 -247.24)"
            />
        </svg>
    ),

    BalanceLink: (
        <svg xmlns="http://www.w3.org/2000/svg" style={{ fill: "var(--blue)" }} viewBox="0 0 24 24" height={20}>
            <path d="M3 18h13v-2H3v2zm0-5h10v-2H3v2zm0-7v2h13V6H3zm18 9.59L17.42 12 21 8.41 19.59 7l-5 5 5 5L21 15.59z"></path>
        </svg>
    ),

    Items: (
        <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 104.42">
            <path d="M3.66,10.84a3.71,3.71,0,0,1,0-7.42H9.11A17.25,17.25,0,0,1,18,5.77c4.92,3.11,5.79,7.56,7.05,12.59H66.73a31.57,31.57,0,0,0-.91,7.33H27.15l8.37,31.47H94.81l0-.18c.72.05,1.44.08,2.17.08a31.59,31.59,0,0,0,5.46-.48l-1.29,5.19a3.6,3.6,0,0,1-3.57,2.81H37.49c1.31,4.88,2.63,7.51,4.42,8.73,2.16,1.41,5.92,1.51,12.21,1.41H96.68a3.67,3.67,0,1,1,0,7.33H54.21c-7.79.1-12.59-.09-16.44-2.63s-6-7.14-8.08-15.31h0L17.1,16.48c0-.1,0-.1-.09-.19a6.58,6.58,0,0,0-2.82-4.23A9.64,9.64,0,0,0,9,10.84H3.66ZM97,0A25.85,25.85,0,1,1,78.74,7.57,25.83,25.83,0,0,1,97,0Zm11.43,23.91V27.8a1.67,1.67,0,0,1-1.67,1.67h-6.14v6.14A1.68,1.68,0,0,1,99,37.29H95.08a1.68,1.68,0,0,1-1.67-1.68V29.47H87.27a1.68,1.68,0,0,1-1.68-1.67V23.91a1.68,1.68,0,0,1,1.68-1.67h6.14V16.1a1.68,1.68,0,0,1,1.67-1.68H99a1.68,1.68,0,0,1,1.67,1.68v6.14h6.14a1.68,1.68,0,0,1,1.67,1.67Zm3.72-13.2a21.43,21.43,0,1,0,6.27,15.15,21.38,21.38,0,0,0-6.27-15.15ZM60.9,33.43a2.61,2.61,0,0,1,5.11,0V47.6a2.6,2.6,0,0,1-5.11,0V33.43Zm-15.31,0a2.61,2.61,0,0,1,5.11,0V47.6a2.6,2.6,0,0,1-5.11,0V33.43Zm40.1,53a9,9,0,1,1-9,9,9,9,0,0,1,9-9Zm-39.56,0a9,9,0,1,1-9,9,9,9,0,0,1,9-9Z" />
        </svg>
    ),
    Heart: (
        <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 720 720">
            <path d="m180 45c-99.36 0-180 80.64-180 180 0 47.8 18.66 91.26 49.094 123.5l309.91 326.5 315.34-329.31c31.32-38.01 41.85-70.21 45.66-120.69 0-99.36-80.64-180-180-180-91.55 0-167.21 68.48-178.53 156.97h-2.94c-11.32-88.49-86.98-156.97-178.53-156.97z" />
        </svg>
    ),
};

const Icon = (props) => {
    return <ima props />;
};

export default Svgs;

const ICONS = {
    Persion: "/public/icons/user.png",
    Password: "/public/icons/password.png",
    Phone: "/public/icons/phone.png",
};
