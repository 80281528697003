import { doc, getDoc, collection, getDocs, limit, query as createQuery, setDoc, where, startAt, orderBy, startAfter } from "firebase/firestore";
import Logger from "../NodeBuilder/Logger";
import { fdb } from "./firebase";

const FirebaseApi = {
    // getRealTime: async (url) => (await get(ref(db, url))).val() || [],
    get: async (url) => {
        console.log("get", url);
        url = url.split("?");
        const query = getQuery(url[1]);
        const root = url[0].replace("/", "");
        console.log({ url, query });
        return await GetDataQuery(root, query);
    },
    getDoc: async (_doc, id) => {
        Logger({ _doc, id });
        const docRef = doc(fdb, "userItems", id);
        const docSnap = await getDoc(docRef);
        Logger(docSnap.exists());
        return docSnap.data() || [];
    },
};
const GetDataQuery = async (_doc, queries) => {
    const q = createQuery(collection(fdb, _doc), ...queries);
    const querySnapshot = await getDocs(q);
    let arr = [];
    querySnapshot.forEach((doc) => {
        arr.push(doc.data());
    });
    console.log({ arr });
    return arr;
};

export default FirebaseApi;

const getQuery = (query) => {
    const queries = [];
    query &&
        query.split("&")?.forEach((q) => {
            const [key, value] = q.split("=");
            const func = QueryKeys[key];
            if (func) return queries.push(func(value));
        });
    // queries.push(where("name", "==", "ر"));
    return queries;
};

const QueryKeys = {
    limit: (value) => limit(value),
    orderBy: (value) => orderBy(value, "desc"),
    orderByAsc: (value) => orderBy(value, "asc"),
    startAt: (value) => startAt(value),
    startAfter: (value) => startAfter(value),
    where: (value) => {
        // const [key, opStr, val] = value.split(",");
        // const op = WhereOps[opStr];
        // return where(key, op, val);
    },
};
