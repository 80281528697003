import React from "react";
import { JsonBuilder } from "../NodeBuilder/JsonParser";

let startIndex = 0;
let endIndex = 0;

const Grid = ({ provider, ItemBuilder = JsonBuilder, className = "grid", onClick }) => {
    [provider.items, provider.setItems] = React.useState(provider.items);

    provider.setItem = React.useMemo(() => (item) => provider.setItems((items) => items.map((i) => (i.id === item.id ? item : i))), []);

    return (
        <div id="grid-container" className={className} onClick={onClick}>
            {provider.items.map((item, i) => (
                <ItemBuilder key={i} item={item} provider={provider} />
            ))}
        </div>
    );
};

export default React.memo(Grid);
